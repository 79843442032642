<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="companyName" placeholder="客户姓名"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="shopCity" placeholder="城市"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="shopName" placeholder="店铺"></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 10px;margin-left: 10px">
      <el-button @click="dialog.addCompanyState = true" style="margin-bottom: 10px" type="primary">添加客户</el-button>
      <el-table
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :row-style="{height:20+'px'}"
          :cell-style="{padding:5+'px'}"
          :data="table.companyList"
          v-loading="loading"
          border
          height="650"
          max-height="650"
          :default-sort = "{prop: 'id', order: 'ascending'}"
          style="width: 100%">
        <el-table-column prop="companyName" label="客户名" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center" ></el-table-column>
        <el-table-column prop="shopCity" label="城市" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="shopName" label="店铺" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="weChat" label="微信" align="center" ></el-table-column>
        <el-table-column prop="phone" label="电话" align="center" ></el-table-column>
        <el-table-column width="350" align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary" size="mini" @click="addUser(scope.row)">添加员工</el-button>
              <el-button type="success" size="mini" @click="addOrder(scope.row)">添加订单</el-button>
              <el-button type="warning" size="mini" @click="addEdit(scope.row)">编辑</el-button>
              <el-button type="danger" size="mini" @click="deleteCompany(scope.row)">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-row>
    <userAdd v-if="dialog.addUserState" :company="dialog.company" :state="dialog.addUserState" @close="dialog.addUserState=false"/>
    <orderAdd v-if="dialog.addOrderState" :company="dialog.company" :state="dialog.addOrderState" @close="dialog.addOrderState=false"/>
    <companyAdd v-if="dialog.addCompanyState" :state="dialog.addCompanyState" @close="close"/>
    <companyEdit v-if="dialog.companyEditState" :company="dialog.company" :state="dialog.companyEditState" @close="close" />
  </div>
</template>

<script>
import userAdd from '@/pages/train/user/user-add'
import orderAdd from '@/pages/train/order/order-add'
import companyAdd from '@/pages/train/company/company-add'
import companyEdit from '@/pages/train/company/company-edit'
export default {
  name: "company-list",
  data(){
    return{
      companyName:"",
      shopCity:"",
      shopName:"",
      tenantCrop:localStorage.getItem("tenantCrop"),
      table:{
        companyList:[],
        page:1,
        limit:100,
        total:0,
      },
      dialog:{
        addUserState:false,
        company:{},
        addOrderState:false,
        addCompanyState:false,
        companyEditState:false,
      },
      loading: true
    }
  },
  components:{
    userAdd,
    orderAdd,
    companyAdd,
    companyEdit,
  },
  created() {
    this.queryCompanyList();
  },
  methods:{
    search(){
      this.table.page=1;
      this.queryCompanyList();
    },
    //查询客户列表
    queryCompanyList:function (){
      this.$axios({
        method:"GET",
        url:"/trainCompany/queryTrainCompanyList",
        params:{
          page:this.table.page,
          limit:this.table.limit,
          companyName:this.companyName,
          shopCity:this.shopCity,
          shopName:this.shopName,
          tenantCrop:this.tenantCrop,
        }
      }).then(response=>{
        this.loading = false
        this.table.total=response.data.data.total;
        this.table.companyList=response.data.data.list;
      })
    },
    //打开添加员工界面
    addUser(data) {
      this.dialog.addUserState = true
      this.dialog.company = data
    },
    //打开添加订单界面
    addOrder(data){
      this.dialog.addOrderState = true
      this.dialog.company = data
    },
    //打开编辑界面
    addEdit(data){
      this.dialog.companyEditState = true
      this.dialog.company = data
    },
    //删除客户
    deleteCompany(data){
      this.$confirm("删除客户", '删除客户:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method:"POST",
          url:"/trainCompany/deleteTrainCompanyById",
          params:{
            id:data.id
          }
        }).then(response=>{
          if (response.data.code === 200) {
            this.$message.success("删除成功!")
            let self=this;
            setTimeout(function (){self.queryCompanyList()},1000)
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //更改每页长度
    handleSizeChange:function (val){
      this.table.limit=val;
      this.queryCompanyList();
    },
    handleCurrentChange(val) {
      this.table.page =val
      this.queryCompanyList()
    },
    close(){
      this.dialog.addCompanyState=false;
      this.dialog.companyEditState=false
      this.queryCompanyList();
    }
  }
}
</script>

<style scoped>

</style>